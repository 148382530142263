import React, { useState, useEffect } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  LogoutUser,
  getUser,
  getBusinessDetails,
} from "../../redux/slices/auth";
import "./Sidebar.css";
import logo from "../../Images/Logo-blue.png";
import { useNavigate, useLocation } from "react-router-dom";
import { TenantNav_Buttons, Profile_Menu } from "../../data";
import { SignOut } from "phosphor-react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const getPath = (index) => {
  switch (index) {
    case 0:
      return "/browse";
    case 1:
      return "/applications";
    case 2:
      return "/watchlist";
    case 3:
      return "/setting";
    default:
      break;
  }
};

const TenantSidebar = () => {
  const { user, isLoggedIn, user_id } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(
    location?.pathname.includes("/browse")
      ? 0
      : location?.pathname.includes("/applications")
      ? 1
      : location?.pathname.includes("/watchlist")
      ? 2
      : location?.pathname.includes("/setting")
      ? 3
      : 0
  );

  useEffect(() => {
    dispatch(getUser());
    // dispatch(getBusinessDetails(user_id));
  }, []);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#6341E0" : "#308fe8",
    },
  }));

  const boardingStatusObj = {
    yourDetails: "/tenant/details",
    refreeDetails: "/tenant/current-landlord",
    idDocuments: "/tenant/id-document",
    addressHistory: "/tenant/address-history",
    prefrence: "/tenant/search-preference",
    rentalStatus: "/tenant/rental-status",
    notification: "/tenant/notification-setting",
  };

  const handleForms = () => {
    const { preApproval, searchPrefrence, notification } =
      user.tenantOnboardingStatus;
    const mergedObj = { ...preApproval, ...searchPrefrence, notification };

    const boardingStatuskeys = Object.keys(mergedObj);
    const filteredData = boardingStatuskeys.filter((item) => {
      return mergedObj[item] === false;
    });

    for (let value of filteredData) {
      navigate(`${boardingStatusObj[value]}`);
      break;
    }
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar_sec">
          <div className="sidebar_layout">
            <header>
              <img alt={logo} src={logo} onClick={() => { navigate('/browse'); setSelected(0); }} style={{ cursor: "pointer" }} />
            </header>
            {
              isLoggedIn && user?.onBoardingSteps < 5 ? <div className="Purchase_Credits">
                <div className="Reazy_Credits">
                  <h5>
                    Onboarding Steps{" "}
                    <span>{user && user?.onBoardingSteps}</span>
                  </h5>
                </div>
                <Box sx={{ flexGrow: 1 }} className="mt-3 mb-3">
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      user && user?.onBoardingSteps === 2
                        ? 40
                        : user && user?.onBoardingSteps === 3
                          ? 60
                          : user && user?.onBoardingSteps === 4
                            ? 80
                            : 0
                    }
                  />
                </Box>
                <Button
                  type="button"
                  className=" btn-primary"
                  onClick={handleForms}
                >
                  Complete
                </Button>
              </div> : null

            }

            <div className="sidebar_content">
              {TenantNav_Buttons.map((item) =>
                item.index === selected ? (
                  <a
                    key={item.index}
                    className="active"
                    onClick={() => {
                      setSelected(item.index);
                      // if (!isLoggedIn) {
                      //   navigate("/tenant-login");
                      // } else {
                        navigate(getPath(item.index));
                      // }
                    }}
                  >
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      setSelected(item.index);
                      if (!isLoggedIn) {
                        navigate("/tenant-login");
                      } else {
                        navigate(getPath(item.index));
                      }
                    }}
                  >
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                )
              )}
            </div>
            <div className="SidebarFooter">
              {Profile_Menu.map((item) =>
                item.index === selected ? (
                  <a key={item.index} className="active">
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      setSelected(item.index);
                      if (!isLoggedIn) {
                        navigate("/tenant-login");
                      } else {
                        navigate(getPath(item.index));
                      }
                    }}
                  >
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                )
              )}
              <div className="user_details">
                {/* <div className="after_log"> */}
                {/* {isLoggedIn ? <>
                  {user && user?.avatarUrl ? (

                    <img
                      src={user.avatarUrl}
                      alt=""
                    />
                  ) : (
                    <div className="blank_image"
                    // style={{
                    //   backgroundColor: "#F7F7FD",
                    //   padding: "10px",
                    //   borderRadius: "50%",  
                    //   color: 'black',
                    //   border: '2px solid pink'
                    // }}
                    >
                      {user?.firstName && user?.firstName.slice(0, 1)}
                      {user.lastName && user?.lastName.slice(0, 1)}
                    </div>
                  )}
                </> : null} */}
                {isLoggedIn ? <> <div className="after_log">
                <>
                  {user && user?.avatarUrl ? (

                    <img
                      src={user.avatarUrl}
                      alt=""
                    />
                  ) : (
                    <div className="blank_image"
                    // style={{
                    //   backgroundColor: "#F7F7FD",
                    //   padding: "10px",
                    //   borderRadius: "50%",  
                    //   color: 'black',
                    //   border: '2px solid pink'
                    // }}
                    >
                      {user?.firstName && user?.firstName.slice(0, 1)}
                      {user.lastName && user?.lastName.slice(0, 1)}
                    </div>
                  )}
                </>
                  <h5>
                    {user.firstName} {user.lastName}
                    <Tooltip title={user?.email} arrow>
                      <p className="profile_mail">
                        {" "}
                        <span style={{ fontWeight: "10px" }}>
                          {user && user?.email && user?.email.length > 15 ? <p>{user?.email.slice(0, 3)}...@...{user?.email.slice(user?.email.length - 4, user?.email.length)}</p> : <p>{user?.email}</p>}
                        </span>{" "}
                      </p>
                    </Tooltip>
                  </h5><Tooltip title="Logout" arrow>
                    <IconButton
                      onClick={() => {
                        dispatch(LogoutUser(navigate));
                      }}
                    >
                      <SignOut />
                    </IconButton>
                  </Tooltip></div></> : <><Button className="btn" variant="contained" onClick={() => navigate('/tenant-login')}>SignIn</Button>Don't have an account?
                  <a href="/tenant-register">Sign up-it's FREE!</a></>}



                {/* {isLoggedIn ?
                    <h5>
                      {user.firstName} {user.lastName}
                      <Tooltip title={user?.email} arrow>
                        <p className="profile_mail">
                          {" "}
                          <span style={{ fontWeight: "10px" }}>
                            {user && user?.email && user?.email.length > 15 ? <p>{user?.email.slice(0, 3)}...@...{user?.email.slice(user?.email.length - 4, user?.email.length)}</p> : <p>{user?.email}</p>}
                          </span>{" "}
                        </p>
                      </Tooltip>
                    </h5>
                    : <><Button className="btn" variant="contained" onClick={() => navigate('/tenant-login')}>SignIn</Button>Don't have an account?
                      <a href="/tenant-register">Sign up-it's FREE!</a></>}
                  {isLoggedIn ?
                    <Tooltip title="Logout" arrow>
                      <IconButton
                        onClick={() => {
                          dispatch(LogoutUser(navigate));
                        }}
                      >
                        <SignOut />
                      </IconButton>
                    </Tooltip> : null
                  } */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantSidebar;
