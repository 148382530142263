import React, { useState } from "react";
import Property from "../../Images/properties.png";
import Bed from "../../Images/bed.png";
import Bath from "../../Images/bath.png";
import Area from "../../Images/area.png";
import "./Landing.css";
import Header from "./Header";
import Footer from "./Footer";


function Terms() {

  return (
    <>
      <Header />
      <div className="landining_main terms-fixed">
        <div className="container">
          <div className="landining_main_grid">
            <div className="landining_left terms-condiions">
              <h1 className="terms-heading">Terms and Conditions</h1>
              <p>
                Data Collection and Usage
              </p>

              <ol >
                <li>Data Collection:
                  <ul>
                    <li>Reazy collects personal information from tenants, including but not limited to, name, address, employment details, financial information, and rental history.</li>
                    <li>Landlords and agents provide property details and criteria for tenant approval.</li>
                  </ul>
                </li>
                <li>Data Usage:
                  <ul>
                    <li>The collected data is used to verify the identity and credibility of tenants, ensuring they meet the approval criteria set by landlords and agents.</li>
                    <li>Tenant data is also used to match tenants with properties they are pre-approved for.</li>
                  </ul>
                </li>
                <li>Consent:
                  <ul>
                    <li>By using Reazy, tenants consent to the collection, storage, and processing of their personal data for the purposes of reference checking and matching with suitable rental properties.</li>
                    <li>Landlords and agents consent to the collection, storage, and processing of property and approval criteria data.</li>
                  </ul>
                </li>
                <li>Purpose Limitation:
                  <ul>
                    <li>Personal data collected will be used solely for the purposes of tenant verification and property matching. It will not be used for any other purposes without explicit consent from the user.</li>

                  </ul>
                </li>
                <li>Third-Party Data Sources:
                  <ul>
                    <li>Reazy may collect data from third-party sources such as credit agencies, public records, and previous landlords to enhance the accuracy of reference checks.</li>

                  </ul>
                </li>
              </ol>
              <p> Account Registration and Management</p>
              <ol >
                <li>Account Creation:
                  <ul>
                    <li>Users may create an account (Account) to access the Reazy platform. Registration may require providing personal details, including a valid email address, telephone number, and a username and password for login purposes (Registration Details).</li>

                  </ul>
                </li>
                <li>Updating Information:
                  <ul>
                    <li>If your registration information changes, you must promptly update your Account to reflect those changes.</li>
                  </ul>
                </li>
                <li>Account Security:
                  <ul>
                    <li>You are responsible for keeping your Account details, including username and password, secure and confidential at all times.</li>
                    <li>Notify Reazy immediately of any unauthorized use of or access to your Account, or any other breaches of security.</li>
                  </ul>
                </li>
                <li>Account Suspension and Termination:
                  <ul>
                    <li>Reazy has the right to suspend or terminate your access to your Account at any time if you breach these Terms and Conditions.</li>

                  </ul>
                </li>
                <li>Additional Account Holders:
                  <ul>
                    <li>You may add additional account holders by agreeing with Reazy in advance to vary your subscription plan or through the relevant function in your Account and paying any applicable fees.</li>

                  </ul>
                </li>
                <li>Account Responsibility:
                  <ul>
                    <li>It is your responsibility to verify the identity of users associated with your Account and ensure the security of your Account.</li>
                    <li>Reazy will not be held liable for any unauthorized access to or use of your Account.</li>
                  </ul>
                </li>
              </ol>
              <p>
                Use of Platform
              </p>

              <ol >
                <li>Lawful Use:
                  <ul>
                    <li>You must use the Reazy platform for lawful purposes only.</li>
                  </ul>
                </li>
                <li>Prohibited Actions:
                  <ul>
                    <li>Do not commit any act or engage in any practice that is harmful to our systems, reputation, or goodwill, or interferes with the integrity of the platform.</li>
                    <li>Do not create Accounts through unauthorized means, such as using an automated device, script, bot, or other similar methods.</li>
                    <li>Do not restrict or attempt to restrict another user from using the platform or encourage violations of these Terms and Conditions.</li>
                    <li>Do not distribute communications containing spam, chain letters, or pyramid schemes.</li>
                    <li>Do not collect information about others, including registration information, without their consent.</li>
                    <li>Do not bypass measures used to prevent or restrict access to the platform.</li>
                    <li>Do not interfere with the privacy of, harass, intimidate, act violently or inappropriately towards, or be discriminatory against any user.</li>
                    <li>Do not infringe any intellectual property rights or any other contractual or proprietary rights of any third party.</li>
                  </ul>
                </li>
                <li>User Responsibility:
                  <ul>
                    <li>You are responsible for determining who your users are and are responsible for their use of the platform.</li>
                    <li>You must ensure compliance with any relevant legislation and regulations applicable to any contractual relationship you enter into with other users.</li>
                    <li>Reazy is not obliged to confirm the identity of users and does not control the conduct of users on the platform.</li>
                  </ul>
                </li>
                <li>Content Monitoring:
                  <ul>
                    <li>Reazy reserves the right, but is not obliged, to monitor, review, verify, edit, modify, or delete user content and does not control the accuracy or completeness of user content.</li>

                  </ul>
                </li>

              </ol>
              <p>
                Usage Limits
              </p>

              <ol >
                <li>Service Limits:
                  <ul>
                    <li>Your service plan may include limits on the number of reference checks that can be conducted during the term (Usage Limit). Your use of the platform is subject to these Usage Limits.</li>
                  </ul>
                </li>
                <li>Exceeding Usage Limits:
                  <ul>
                    <li>If you reach your Usage Limit, you will not be able to conduct further reference checks unless you add additional checks by varying your subscription plan or through your Account and paying any applicable fees.</li>
                  </ul>
                </li>
                <li>Unused Reference Checks:
                  <ul>
                    <li>Unused reference checks do not roll over to subsequent terms.</li>
                  </ul>
                </li>
              </ol>

              <p>
              Data Protection and Privacy
              </p>

              <ol >
                <li>Security:
                  <ul>
                    <li>Reazy implements robust security measures to protect personal data from unauthorized access, disclosure, alteration, or destruction.</li>
                    <li>Users are responsible for maintaining the confidentiality of their login credentials.</li>
                  </ul>
                </li>
                <li>Data Retention:
                  <ul>
                    <li>Reazy retains personal data for as long as necessary to fulfill the purposes outlined in these Terms and Conditions, or as required by law.</li>
                    <li>Upon termination of use or upon request, personal data may be deleted or anonymized, subject to legal obligations.</li>
                  </ul>
                </li>
                <li>User Rights:
                  <ul>
                    <li>Users have the right to access, correct, or delete their personal data. Requests can be made through the Reazy support team.</li>
                    <li>Users can also request restrictions on data processing or object to data processing under certain circumstances.</li>
                  </ul>
                </li>
                <li>Privacy Policy:
                  <ul>
                    <li>Reazy's Privacy Policy provides further details on how personal data is collected, used, and protected. Users are encouraged to review the Privacy Policy in conjunction with these Terms and Conditions.</li>

                  </ul>
                </li>

              </ol>
              <p>
              Liability
              </p>

              <ol >
                <li>Disclaimer:
                  <ul>
                    <li>Reazy does not guarantee the approval of any tenant or the availability of properties.</li>
                    <li>Reazy is not liable for any decisions made by landlords or agents based on the reference checks conducted.</li>
                  </ul>
                </li>
                <li>Indemnification:
                  <ul>
                    <li>Users agree to indemnify and hold Reazy harmless from any claims, damages, or expenses arising out of the misuse of the platform or the provision of false or misleading information.</li>
                  </ul>
                </li>
                <li>Limitation of Liability:
                  <ul>
                    <li>To the fullest extent permitted by law, Reazy's liability for any claims arising from these Terms and Conditions is limited to the amount paid by the user for the use of the platform.</li>
                  </ul>
                </li>
                <li>Dispute Resolution:
                  <ul>
                    <li>Any disputes arising out of or relating to these Terms and Conditions shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.</li>

                  </ul>
                </li>
                <li>Governing Law:
                  <ul>
                    <li>These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which Reazy operates, without regard to its conflict of law principles.</li>

                  </ul>
                </li>
              </ol>
            </div>
            <div className="landining_right">


            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms;
