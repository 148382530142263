// import { Listings_icon } from "./icons";
import {
  User,
  Users,
  HouseSimple,
  Copy,
  Notepad,
  Gear,
  Eye,
} from "phosphor-react";

export const AgentNav_Buttons = [
  {
    index: 0,
    icon: <HouseSimple />,
    text: "Properties",
  },
  {
    index: 1,
    icon: <Copy />,
    text: "Listing",
  },
  {
    index: 2,
    icon: <Users />,
    text: "Tenants",
  },
  {
    index: 3,
    icon: <Notepad />,
    text: "Applications",
  },
];

export const TenantNav_Buttons = [

  {
    index: 0,
    icon: <HouseSimple />,
    text: "Properties",
  },
  {
    index: 1,
    icon: <Notepad />,
    text: "Applications",
  },
  {
    index: 2,
    icon: <Eye />,
    text: "Watchlist",
  },
];

const Profile_Menu = [
  // {
  //   index: 4,
  //   text: "Profile",
  //   icon: <User />,
  // },
  {
    index: 3,
    text: "My Account",
    icon: <Gear />,
  },
];
export { Profile_Menu };

const Agent_Profile_Menu = [
  // {
  //   index: 4,
  //   text: "Profile",
  //   icon: <User />,
  // },
  {
    index: 4,
    text: "My Account",
    icon: <Gear />,
  },
];

export {Agent_Profile_Menu}

// select features data./

export const Select_Features = [
  {
    index: 0,
    text: "Furnished",
  },
  {
    index: 1,
    text: "Pets Friendly",
  },
  {
    index: 2,
    text: "Swimming Pool",
  },
  {
    index: 3,
    text: "Balcony",
  },
  {
    index: 4,
    text: "Garage",
  },
  {
    index: 5,
    text: "Outdoor Area",
  },
  {
    index: 6,
    text: "Free Parking",
  },
  {
    index: 7,
    text: "Garden",
  },
  {
    index: 8,
    text: "Dishwasher",
  },
  {
    index: 9,
    text: "Built-in robes",
  },
  {
    index: 10,
    text: "Study",
  },
  {
    index: 11,
    text: "Air Conditioning",
  },
];
