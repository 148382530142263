import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { updateIsLoading, getUser, getBusinessDetails } from "../slices/auth";

const initialState = {
  snackbar: {
    open: null,
    message: null,
    severity: null,
  },
  property: {
    property_id: null,
    address_id: null,
    address: null,
    info_id: null,
    info: null,
    media_id: null,
    media: null,
    business_id: null,
  },
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalProperties: null,
    noOfPropertiesPerPage: null,
  },
  propertyList: [],
  propertyData: null,
  isEditingProperty: false,
  propertySteps: 0,
  memberDocData: null,
  notificationData: null,
  cardInfo: null,
  cardInfoStatus: false,
  imageUploaded: false,
  allTenant: [],
  searchedResult: [],
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    openSnackBar(state, action) {
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },
    closeSnackBar(state, action) {
      state.snackbar.open = false;
      state.snackbar.message = null;
    },
    addaddress(state, action) {
      if (action.payload.address_id) {
        state.property.address_id = action.payload.address_id;
        state.property.property_id = action.payload.property_id;
      } else {
        state.property.address = action.payload.address;
      }
    },
    propertyInfo(state, action) {
      if (action.payload.info_id) {
        state.property.info_id = action.payload.info_id;
      } else {
        state.property.info = action.payload.info;
      }
    },
    propertyMedia(state, action) {
      if (action.payload.media_id) {
        state.property.media_id = action.payload.media_id;
      } else {
        state.property.media = action.payload.media;
      }
    },
    // property(state, action) {
    //   state.property.address_id = action.payload.address_id,
    //   state.property.address = action.payload.address,
    //   state.property.info_id = action.payload.info_id,
    //   state.property.info = action.payload.info,
    //   state.property.media_id = action.payload.media_id,
    //   state.property.media = action.payload.media
    // },
    allPropertyList(state, action) {
      state.propertyList = action.payload.list;
      state.pagination.pageNumber = action.payload.pageNumber;
      state.pagination.pageSize = action.payload.pageSize;
      state.pagination.totalProperties = action.payload.totalProperties;
      state.pagination.noOfPropertiesPerPage =
        action.payload.noOfPropertiesPerPage;
    },
    setAllTenant(state, action) {
      state.allTenant = action.payload.allTenant;
      state.pagination.pageNumber = action.payload.pageNumber;
      state.pagination.pageSize = action.payload.pageSize;
      state.pagination.totalProperties = action.payload.totalProperties;
      state.pagination.noOfPropertiesPerPage =
        action.payload.noOfPropertiesPerPage;
    },
    getProperty(state, action) {
      // state.property.address_id = action.payload.propertyData.propertyAddress_id
      state.property.property_id = action.payload.propertyData.property_id;

      state.propertyData = action.payload.propertyData;
    },
    getSearchProperty(state, action) {
      console.log(action.payload, "88888888888888888888888")
      state.searchedResult = action.payload.properties;
    },
    setEmptyProperty(state, action) {
      state.searchedResult = [];
    },

    getNotification(state, action) {
      state.notificationData = action.payload.notificationData;
    },

    getCardDetails(state, action) {
      state.cardInfo = action.payload.cardInfo;
    },
    getCardStatus(state, action) {
      state.cardInfoStatus = action.payload.cardInfoStatus;
    },

    setEditProperty(state, action) {
      state.isEditingProperty = action.payload;
    },
    getAllTeamMember(state, action) {
      state.memberDocData = action.payload.memberDocData;
    },
    setEditProperty(state, action) {
      state.isEditingProperty = action.payload;
    },

    updatePropertySteps(state, action) {
      state.propertySteps = action.payload.propertySteps;
    },
    signOutProperty(state, action) {
      state.property.property_id = null;
      state.property.address_id = null;
      state.property.address = null;
      state.property.info_id = null;
      state.property.info = null;
      state.property.media_id = null;
      state.property.media = null;
      state.property.business_id = null;
      state.propertyList = [];
      state.propertyData = null;
      state.isEditingProperty = false;
      state.propertySteps = 0;
      state.memberDocData = null;
      state.notificationData = null;
      state. cardInfo= null;
      state.cardInfoStatus= false;
      state. imageUploaded= false;
      state.allTenant= [];
      state. searchedResult= [];
    },
    deleteProperty(state, action) {
      state.property.property_id = null;
      state.property.address_id = null;
      state.property.info_id = null;
      state.property.info = null;
      state.property.media_id = null;
      state.property.media = null;
    },
    SetImageUploaded(state, action) {
      state.imageUploaded = action.payload.imageUploaded;
    },
  },
});

export default slice.reducer;

export function LogoutProperty() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.signOutProperty());
  };
}

export const ShowSnackBar =
  ({ severity, message }) =>
  async (dispatch, getState) => {
    dispatch(
      slice.actions.openSnackBar({
        message,
        severity,
      })
    );
    setTimeout(() => {
      dispatch(slice.actions.closeSnackBar());
    }, 4000);
  };

export const CloseSnackBar = () => async (dispatch, getState) => {
  dispatch(slice.actions.closeSnackBar());
};

// export function AddAddress(address_id) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.addaddress(address_id));
//   };
// }
// export function propertyInformation(info) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.propertyInfo(info));
//   };
// }git
// export function Media(media) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.propertyMedia(media));
//   };
// }
// export function UpdateSidebarType(type) {
//   return async (dispatch, getState) => {
//     dispatch(
//       slice.actions.updateSidebarType({
//         type,
//       })
//     );
//   };
// }

export function AddAddressFunc(formValues) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/property/property-address",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.data.address_id && response.data.property_id) {
          dispatch(
            slice.actions.addaddress({
              address_id: response.data.address_id,
              property_id: response.data.property_id,
            })
          );
          formValues.navigate("/property-information");
          // window.location.href = "/property-information";
        }
        if (response.data.message === "Address Edited") {
          dispatch(GetProperty(formValues?.pid));
          formValues.navigate("/property-information");
        }
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error && !getState().auth.isLoading) {
          dispatch(slice.actions.updatePropertySteps({ propertySteps: 1 }));
        }
      });
  };
}

export function PropertyInfoFunc(formValues) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    let renderToMedia;
    let renderToInfoSecond;
    await axios
      .post(
        "/property/property-information",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.propertyInfo({ info_id: response.data.info_id })
        );
        dispatch(GetProperty(formValues?.property_id));
        if (response.data.path === "/property-media") {
          renderToMedia = response.data.path;
        } else if (response.data.path === "/property-info-second") {
          renderToInfoSecond = response.data.path;
        }

        // if(getState().app.isEditingProperty === true){
        //   dispatch(slice.actions.getProperty(getState().app.property.property_id))
        // }
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (
          !getState().auth.error &&
          !getState().auth.isLoading &&
          renderToInfoSecond === "/property-info-second"
        ) {
          // window.location.href = renderToInfoSecond;
          formValues.navigate(renderToInfoSecond);
          dispatch(slice.actions.updatePropertySteps({ propertySteps: 2 }));
        } else if (
          !getState().auth.error &&
          renderToMedia === "/property-media"
        ) {
          // window.location.href = renderToMedia;
          formValues.navigate(renderToMedia);
          dispatch(slice.actions.updatePropertySteps({ propertySteps: 3 }));
        }
      });
  };
}

export function PropertyMediaFunc(
  files,
  data,
  featuredFile,
  floorFile,
  navigate,
  deletedElements
) {
  const formData = new FormData();

  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    formData.append("file", element);
  }

  formData.append("propertyId", data.propertyId);
  formData.append("propertyMediaEdit", data.propertyMedia);
  formData.append("featuredMediaEdit", data.featuredMedia);
  formData.append("propertyMediaId", data.propertyMediaId);
  formData.append("featuredFile", featuredFile);
  formData.append("tempData", deletedElements);
  formData.append("floorPlan", floorFile);
  return async (dispatch, getState) => {
    dispatch(slice.actions.SetImageUploaded({ imageUploaded: true }));
    await axios
      .post("/property/property-media", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then(function (response) {
        dispatch(slice.actions.SetImageUploaded({ imageUploaded: false }));
        dispatch(
          slice.actions.propertyMedia({
            media_id: response.data.media_id,
          })
        );
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(GetProperty(data?.propertyId));
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
        dispatch(slice.actions.SetImageUploaded({ imageUploaded: false }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate("/review-property");
          dispatch(slice.actions.updatePropertySteps({ propertySteps: 4 }));
        }
      });
  };
}

export function GetPropertyAddress(id) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/property/get_address/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.addaddress({ address: response.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function GetPropertyInfo(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/property/get_info/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.propertyInfo({ info: response.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function GetPropertyMedia(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/property/get_media/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.propertyMedia({ media: response.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function CreateProperty(data) {
  return async (dispatch, getState) => {
    await axios
      .post(
        "/property/create",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   slice.actions.property({
        //     address_id: null,
        //     address: null,
        //     info_id: null,
        //     info: null,
        //     media_id: null,
        //     media: null,
        //   })
        // );
        // dispatch(slice.actions.propertyInfo({ info: response.data }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          dispatch(slice.actions.setEditProperty());
          // window.location.href = "/properties";
          data.navigate("/properties");
        }
      });
  };
}

export function GetAllPropertiesOfBusiness({ pgNum = 1, pgSize = 2,searchQuery } = {}) {
  return async (dispatch, getState) => {
    await axios
      .get(`/property/get_all_by_business?pgNum=${pgNum}&pgSize=${pgSize}&searchQuery=${searchQuery?searchQuery:""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.allPropertyList({
            list: response.data.allProperties,
            pageNumber: response.data.pageNumber,
            pageSize: response.data.pageSize,
            totalProperties: response.data.totalProperties,
            noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
          })
        );
        // dispatch(
        //   ShowSnackBar({ severity: "success", message: response.data.message })
        // );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        // dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      });
  };
}

export function GetAllPropertiesOfBusinessNotListed() {
  return async (dispatch, getState) => {
    await axios
      .get(`/property/get_all_by_business_not_listed`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.allPropertyList({ list: response.data.allProperties })
        );
        // dispatch(
        //   ShowSnackBar({ severity: "success", message: response.data.message })
        // );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        // dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}

export function GetProperty(id, navigate) {
  return async (dispatch, getState) => {
    await axios
      .get(`/property/get_property/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response?.data?.message === "List has been fetched!") {
          dispatch(
            slice.actions.getProperty({
              propertyData: response?.data?.property,
            })
          );
          navigate("/add_address");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function EditPropertyFunc(id) {
  return async (dispatch, getState) => {
    await axios
      .delete(`/property/edit/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // dispatch(slice.actions.getProperty({ propertyData: response.data.property }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}

export function deletePropertyFunc(id) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.deleteProperty());
    await axios
      .delete(`/property/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // dispatch(slice.actions.getProperty({ propertyData: response.data.property }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        // GetAllPropertiesOfBusiness()
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function deleteMultiplePropertyFunc(deletePro) {
  return async (dispatch, getState) => {
    await axios
      .post(
        "/property/deleteMultipleProperty",
        { deletePro },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}

export function getTeamMember(id) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/auth/get-team-member/${id?.userId}?pgNum=${id?.pgNum}&pgSize=${id?.pgSize}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.getAllTeamMember({
            memberDocData: response.data.memberDoc,
          })
        );
        dispatch(
          allPropertyList({
            pageNumber: response.data.pageNumber,
            pageSize: response.data.pageSize,
            totalProperties: response.data.totalProperties,
            noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function DeleteTeamMember(formValues, businessId, setRefresh) {
  return async (dispatch, getState) => {
    axios
      .post(
        "auth/deleteTeamMember",
        {
          formValues,
          businessId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        setRefresh(true);
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}

export function editTeamMember(formValues, businessId,userId, team, setRefresh) {
  return async (dispatch, getState) => {
    axios
      .post(
        "auth/editTeamMember",
        {
          formValues,
          businessId,userId,
          team,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        setRefresh(true);
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}
// get notification function:
export function GetNotification(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/auth/get-notification/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.getNotification({ notificationData: response.data })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function NotificationFunc(formValues) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/notification",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));

        dispatch(GetNotification(response.data.userId));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}

export function payment(formValues) {
  const { path } = formValues;
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    dispatch(slice.actions.getCardStatus({ cardInfoStatus: false }));

    axios
      .post(
        "auth/credit",

        formValues,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(slice.actions.getCardStatus({ cardInfoStatus: true }));
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(getBusinessDetails(formValues.userId));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(getUser());
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      })
      .finally(() => {
        if (path === "/agent/select-plan") {
          formValues.navigate("/setting");
        }
      });
  };
}

export function renewPlan(formValues) {
  return async (dispatch, getState) => {
    axios
      .post(
        "/auth/auto_renew",
        { formValues },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}

export function cancelBilling(formValues) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    axios
      .post(
        "auth/cancel-billing",

        { formValues },

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        // dispatch(slice.actions.getCardStatus({ cardInfoStatus: true }));

        // dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );

        dispatch(getUser());
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}

export function editCardDetails(formValues) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    axios
      .post(
        "auth/editCard",

        formValues,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(slice.actions.getCardStatus({ cardInfoStatus: true }));

        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );

        dispatch(getUser());
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}
export function getCardInfo(formValues) {
  return async (dispatch, getState) => {
    // dispatch(updateIsLoading({ isLoading: true, error: false }));
    dispatch(slice.actions.getCardStatus({ cardInfoStatus: false }));

    axios
      .post(
        "/auth/getCardInfo",
        { user_id: formValues },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        if (!!response.data.cardInfo) {
          dispatch(
            slice.actions.getCardDetails({ cardInfo: response.data.cardInfo })
          );
        } else {
          dispatch(slice.actions.getCardDetails({ cardInfo: null }));
        }
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}
export function serachProperty(formValues) {
  return async (dispatch, getState) => {
    axios
      .get(
        `/property/search_property/${formValues}`,

        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.getSearchProperty({
            properties: response?.data?.searchedProperty,
          })
        );
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
      })
      .catch((error) => { 
        dispatch(slice.actions.setEmptyProperty());
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}

export function AllTenant({ pgNum = 1, pgSize = 10, searchQuery, filterValue = "" } = {}) {
  return async (dispatch, getState) => {
    axios
      .get(
        `/application/all-tenant?pgNum=${pgNum}&pgSize=${pgSize}&searchQuery=${searchQuery?searchQuery:""}&filterValue=${filterValue}`,

        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.setAllTenant({
            allTenant: response.data.allTenants,
            pageNumber: response.data.pageNumber,
            pageSize: response.data.pageSize,
            totalProperties: response.data.totalProperties,
            noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
          })
        );
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}

export const {
  setEditProperty,
  updatePropertySteps,
  allPropertyList,
  getProperty,
} = slice.actions;
