import React from 'react'
import Logo_ft from "../../Images/landing_footer.png";
function Footer() {

  return (
    <div className='footerl'>
<div className="container">
<div className='footerl_inner'>
<p><img src={Logo_ft} alt="" /> Reazy information and partnership here<br></br> 
Follow us: <a href="">Visit us on facebook</a>  <a href="https://www.instagram.com/reazy.com.au/?igshid=MzRlODBiNWFlZA%3D%3D"> 
Visit us on  instagram</a> <br></br><a className='ma_il' href="mailto:contact@reazy.com.au">contact@reazy.com.au</a></p>
<p>© Reazy 2022</p>
</div>
</div>  
</div>
  )
}

export default Footer