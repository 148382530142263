import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { getUser } from "../../redux/slices/auth";
import AgentSideBar from "./AgentSideBar";
import TenantSidebar from "./TenantSideBar";

const DashboardLayout = () => {
  // const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { user,isLoggedIn } = useSelector((state) => state.auth);
  // if (!isLoggedIn) {
  //   return <Navigate to="/agent-login" />;
  // }

  useEffect(() => {
    if(isLoggedIn){
      dispatch(getUser());
    }
    
  }, []);
  return (
    <>
      <Stack className="Reazy_section">
        {(() => {
          switch (user?.role) {
            case "agent":
              return <AgentSideBar />;
            case "tenant":
              return <TenantSidebar />;
          }
        })()}
        <Outlet />
      </Stack>
    </>
  );
};

export default DashboardLayout;
