import React from "react";
import "./Loading.css"
const LoadingScreen = () => {
  return <>
  <section>
      <div className="loaderMain">
      {/* FULL CIRCLE LOADER  */}
      <div className="loadbox">
        <div className='load'>
          <div className="circle-loader">
            <div className='loadCircle'></div>
            <div className='loadCircle'></div>
            <div className='loadCircle'></div>
            <div className='loadCircle'></div>
            <div className='loadCircle'></div>
            <div className='loadCircle'></div>
            <div className='loadCircle'></div>
            <div className='loadCircle'></div>
            {/* <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div> */}
          </div>
        </div>
      </div>
      <div className="loader_head">
        <h4>Loading...</h4>
      </div>
      
      







      </div>
    </section>
  </>;


};

export default LoadingScreen;
