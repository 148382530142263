import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/auth";
import appReducer from "./slices/app";
import listingReducer from "./slices/listing"
import tenantReducer from "./slices/tenant"
import applicationsReducer from "./slices/applications"
// import {store} from './store'


//slices

const rootPersistConfig = {
    key: "root",
    storage,
    keyPrefix: "redux-",
  };
  
  const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    listing: listingReducer,
    tenant: tenantReducer,
    application: applicationsReducer,
  });

//  export const resetState = () => {
//     const rootReducer = combineReducers({
//       app: appReducer,
//       auth: authReducer,
//       listing: listingReducer,
//       tenant: tenantReducer,
//       application: applicationsReducer,
//     });
  
//     store.replaceReducer(rootReducer);
//   };
  
  export { rootPersistConfig, rootReducer };