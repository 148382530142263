import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import SideBar from "./SideBar";
import "./mainLayout.css";

const AgentLayout = () => {
  const { isLoggedIn, user, role } = useSelector((state) => state.auth);

  var midProfile = false;
  if (
    window.location.pathname === "/agent/complete-profile" ||
    window.location.pathname === "/agent/business-details" ||
    window.location.pathname === "/agent/invite" ||
    window.location.pathname === "/agent/select-plan"
  ) {
    midProfile = true;
  }

  if (isLoggedIn && !midProfile && role === "agent") {
    return <Navigate to="/properties" />;
  }

  const isSelectPlanPage = window.location.pathname === "/agent/select-plan";




  return (
    <>
      <div className="Agent_bgg_data">
      {!isSelectPlanPage && <SideBar />}
        {/* <SideBar /> */}
        <Outlet />
      </div>
    </>
  );
};

export default AgentLayout;
