
import { PATH_DASHBOARD } from "./routes/paths";

export let BASE_URL
if (window.location.origin.includes("http://localhost")) {
    BASE_URL = "http://localhost:8000"
} else if (window.location.origin.includes("https://stage-client.reazy.com.au")) {
    BASE_URL = "https://backend.reazy.com.au"
}
  else if (window.location.origin.includes("https://stage-app.reazy.com.au")) {
    BASE_URL = "https://backend.reazy.com.au"
}
export const DEFAULT_PATH = PATH_DASHBOARD.general.app; // as '/app'