import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";



const DefaultPath = () => {
  const { user } = useSelector((state) => state.auth);


  {(() => {
    switch (user.role) {
      case "agent":
        return <Navigate to="/properties" />;
      case "tenant":
        return <Navigate to="/watchlist" />;
    }
  })()}
};

export default DefaultPath;
