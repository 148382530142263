import React, { useState } from "react";
import "./App.css";
// routes
import Router from "./routes";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { CloseSnackBar, ShowSnackBar } from "./redux/slices/app";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const vertical = "top";
const horizontal = "right";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector(
    (state) => state.app.snackbar
  );
  const [password, setPassword] = useState("");
  const [access, setAccess] = useState(false);
  const [error, setError] = useState(false);

  let protectPassword = process.env.REACT_APP_PROTECTION_KEY;
  let isAuthenticated = null;

  const handleClick = () => {
    if (password === protectPassword) {
      setAccess(true);
      window.localStorage.setItem("State", access.toString());
    } else {
      setError(true);
    }
  };

  if (window !== "undefined" && window.localStorage.getItem("State")) {
    isAuthenticated = window.localStorage.getItem("State");
  } else {
    isAuthenticated = access;
  }

  if (!isAuthenticated) {
    return (
      <>
        <div style={{ position: "relative" }}>
          <div className="main_Protected">
            <div className="Protected_heading">
              <h4>This page is password protected.</h4>
            </div>
            <div className="Protected_input">
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  // value={password}
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <span style={{ fontSize: 12, color: "red" }}>
                  {error ? "Wrong Password" : ""}
                </span>
              </div>
            </div>
            <div className="Protected_btn">
              <button type="submit" className="btn1" onClick={handleClick}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router />
      </LocalizationProvider>

      {message && open ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={4000}
          key={vertical + horizontal}
          onClose={() => {
            dispatch(CloseSnackBar());
          }}
        >
          <Alert
            onClose={() => {
              dispatch(CloseSnackBar());
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  );
}

export default App;
