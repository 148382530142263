import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import {  useSelector } from "react-redux";
import Sidebar from "../../pages/tenant/Sidebar/Sidebar";

const TenantLayout = () => {
  const { isLoggedIn, role } = useSelector((state) => state.auth);
  let  navigate = useNavigate();

  // if (isLoggedIn && role === 'tenant') {
  //    navigate("/tenant/details");  
  // }
  
  return (
    <>
      <section className="ONBoardingStep">
        <Sidebar />
        <Outlet />
      </section>
    </>
  );
};

export default TenantLayout;
