import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  LogoutUser,
  getUser,
  getBusinessDetails,
} from "../../redux/slices/auth";
import Tooltip from "@mui/material/Tooltip";
import "./Sidebar.css";
import logo from "../../Images/Logo-blue.png";
import logofooter from "../../Images/Logo_footer.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AgentNav_Buttons, Agent_Profile_Menu } from "../../data";

import { SignOut } from "phosphor-react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const getPath = (index) => {
  switch (index) {
    case 0:
      return "/properties";
    case 1:
      return "/listings";
      case 2:
        return "/allTenant";
    case 3:
      return "/applications";
    case 4:
      return "/setting";
    default:
      break;
  }
};

const AgentSidebar = () => {
  const { user, user_id, stepComplete, business } = useSelector(
    (state) => state.auth
  );
  // const { cardInfo, cardInfoStatus } = useSelector((state) => state.app);
  const cardInfo=useSelector((state)=>state.app.cardInfo)

  const {credit,creditUsed}  = useSelector((state) => state.auth.business);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selected, setSelected] = useState(
    location?.pathname.includes("/properties")
      ? 0
      : location?.pathname.includes("/listings")
      ? 1
      : location?.pathname.includes("/allTenant")
      ? 2
      : location?.pathname.includes("/applications")
      ? 3
      : location?.pathname.includes("/setting")
      ? 4
      : 0
  );

  useEffect(() => {
    dispatch(getUser());
    dispatch(getBusinessDetails(user_id));
  }, []);

  const redirectToBill = () => {
    navigate("/setting?tab=3", { state: { index: 3 } });
  };
  const redirectToBill1 = () => {
    navigate("/setting", { state: { index: 0 } });
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#6341E0" : "#308fe8",
    },
  }));
  const percentage = Math.min((creditUsed/credit ) *100);
  const changeRoute=()=>{
    navigate("/properties")
    setSelected(0)
  }
  return (
    <>
      <div className="sidebar">
        <div className="sidebar_sec">
          <div className="sidebar_layout">
            <header style={{cursor:"pointer"}} >
              <img alt={logo} src={logo} onClick={changeRoute} />
            </header>
            {/* <Button variant="primary" onClick={handleShow}>
              Open
            </Button> */}
            {/* <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Offcanvas</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                Some text as placeholder. In real life you can have the elements
                you have chosen. Like, text, images, lists, etc.
              </Offcanvas.Body>
            </Offcanvas> */}
            {user && user?.onBoardingSteps === 5 ? null : (
              <div className="Purchase_Credits">
                <div className="Reazy_Credits">
                  <h5>
                    Onboarding Steps{" "}
                    <span>{user && user?.onBoardingSteps}</span>
                  </h5>
                </div>
                <Box sx={{ flexGrow: 1 }} className="mt-3 mb-3">
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      user && user?.onBoardingSteps === 2
                        ? 40
                        : user && user?.onBoardingSteps === 3
                        ? 60
                        : user && user?.onBoardingSteps === 4
                        ? 80
                        : 0
                    }
                  />
                </Box>
                <Button
                  type="button"
                  className=" btn-primary"
                  onClick={() => {
                    if (user && user?.onBoardingSteps === 2) {
                      navigate("/agent/complete-profile");
                    }
                    if (user && user?.onBoardingSteps === 3) {
                      navigate("/agent/business-details");
                    }
                    if (user && user?.onBoardingSteps === 4) {
                      navigate("/agent/invite");
                    }
                  }}
                >
                  Complete
                </Button>
              </div>
            )}

            <div className="sidebar_content">
              {AgentNav_Buttons.map((item) =>
                item.index === selected ? (
                  <a
                    key={item.index}
                    className="active"
                    onClick={() => {
                      setSelected(item.index);
                      navigate(getPath(item.index));
                    }}
                  >
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                ) : (
                  <a
                    key={item.index}
                    onClick={() => {
                      setSelected(item.index);
                      navigate(getPath(item.index));
                    }}
                  >
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                )
              )}
            </div>
            <div className="SidebarFooter">
              {Agent_Profile_Menu.map((item) =>
                item.index === selected ? (
                  <a
                    key={item.index}
                    className="active"
                    onClick={redirectToBill1}
                  >
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      setSelected(item.index);
                      navigate(getPath(item.index));
                    }}
                    key={item.index}
                  >
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </a>
                )
              )}
              <div className="Purchase_Credits">
                <div className="Reazy_Credits">
                  <img src={logofooter} alt={logofooter} />
                  <h5>Reazy</h5>
                </div>
                {cardInfo?.creditSubscription === 1000?<p>Unlimited listings remaining</p>:
                
                <p>
                  {credit && user && user.role === "agent" ? credit-creditUsed : "0"}{" "}
                  listings remaining
                </p>
                }
                {cardInfo && cardInfo?.creditSubscription !== 1000 &&
                    
                    <Box sx={{ flexGrow: 1 }} className="mt-3 mb-3">
                      <BorderLinearProgress
                        variant="determinate"
                        value={credit > 0 ? 100 - percentage : 0}
                      />
                    </Box>
                    }

                <Button
                  onClick={() => redirectToBill()}
                  type="button"
                  className="btn btn-primary"
                >
                  Purchase Listings
                </Button>
              </div>
              <div className="user_details">
                <div className="after_log">
                {user && user?.avatarUrl ? (
                  <img src={user.avatarUrl} alt="" />
                ) : (
                  <div
                    style={{
                      backgroundColor: "#F7F7FD",
                      padding: "10px",
                      borderRadius: "50%",
                      color: "black",
                      border: "2px solid pink",
                    }}
                  >
                    {user?.firstName && user?.firstName.slice(0, 1)}
                    {user.lastName && user?.lastName.slice(0, 1)}
                  </div>
                )}

                <h5>
                  {user.firstName} {user.lastName}
                  <p>{business ? business?.businessName : null}</p>
                  {/* <Tooltip title={user?.email} arrow>
                  <p>
                    {" "}
                    <span style={{ fontWeight: "10px" }}>
                      {user && user?.email && user?.email.length>16 ? <p>{user?.email.slice(0,3)}...@...{user?.email.slice(user?.email.length-4,user?.email.length)}</p>:<p>{user?.email}</p>}
                    </span>{" "}
                  </p>
                  </Tooltip> */}
                </h5>

                <Tooltip title="Logout" arrow>
                  <IconButton
                    onClick={() => {
                      dispatch(LogoutUser(navigate));
                    }}
                  >
                    <SignOut />
                  </IconButton>
                </Tooltip>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentSidebar;
